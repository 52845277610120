import { Navigate, createBrowserRouter } from 'react-router-dom';
import { initBuffetConfig } from './utils/gray/grayConfig';

// 组件形式，useLoaderData 一样没有类型联想，放弃了。

export const router = createBrowserRouter(
  [
    {
      path: '/main/:sdkAppId',
      id: 'root',
      lazy: () => import('./global-components/layout/LayoutMain'),
      children: [
        {
          path: 'overview',
          lazy: () => import('./OverviewContainer'),
        },
        {
          path: 'overview/setting',
          lazy: () => import('./SettingGuideContainer'),
        },
        {
          path: 'chatbot/*',
          children: [
            {
              path: 'basic',
              lazy: () => import('./BasicSettingContainer'),
            },
            {
              path: 'task',
              lazy: () => import('./MultiTaskContainer'),
            },
            {
              path: 'knowledge',
              lazy: () => import('./KnowledgeBaseContainer'),
            },
            {
              path: 'task/ivr',
              lazy: () => import('@src/routes/ivr/BaseIvrContainer'),
            },
            {
              path: 'record',
              lazy: () => import('./PublishRecordContainer'),
            },
            {
              path: 'file',
              lazy: () => import('./FileSearchContainer'),
            },
            {
              path: 'qa-optimization',
              lazy: () => import('./QaOptimizationContainer'),
            },
            {
              path: '*',
              element: <Navigate to="/online" replace />,
            },
          ],
        },
        {
          path: 'config/*',
          children: [
            {
              path: 'setting',
              lazy: () => import('@src/routes/config/GlobalSettingContainer'),
            },
            {
              path: 'workbench',
              lazy: () =>
                import('@src/routes/config/WorkbenchSettingContainer'),
            },
            {
              path: 'developer',
              lazy: () =>
                import('@src/routes/config/DeveloperSettingContainer'),
            },
            {
              path: '*',
              element: <Navigate to="setting" replace />,
            },
          ],
        },
        {
          path: 'manage/*',
          children: [
            {
              path: 'member',
              lazy: () => import('@src/routes/manage/MemberManageContainer'),
            },
            {
              path: 'group',
              lazy: () => import('@src/routes/manage/Group/GroupContainer'),
            },
            {
              path: '*',
              element: <Navigate to="member" replace />,
            },
          ],
        },
        {
          path: 'dataBoard/*',
          children: [
            {
              path: 'monitor',
              lazy: () => import('@src/routes/data-board/DataMonitorContainer'),
            },
            {
              path: 'analysis',
              lazy: () =>
                import('@src/routes/data-board/DataAnalysisContainer'),
            },
            {
              path: 'history',
              lazy: () => import('@src/routes/data-board/ChatHistoryContainer'),
            },
            {
              path: '*',
              element: <Navigate to="analysis" replace />,
            },
          ],
        },
      ],
    },
    {
      path: 'login',
      lazy: () => import('./LoginContainer'),
    },
    {
      path: 'auth3',
      lazy: () => import('./Auth3Container'),
    },
    // 控制台拼写错误，暂时先写成autu3
    {
      path: 'autu3',
      lazy: () => import('./Auth3Container'),
    },
    {
      path: 'online',
      lazy: () => import('@src/routes/OnlineContainer'),
    },
    {
      path: '*',
      element: <Navigate to="/online" replace />,
    },
  ],
  {
    // https://reactrouter.com/en/main/routers/create-browser-router#unstable_datastrategy
    // DESC:
    // 可以用 unstable_dataStrategy 实现所有路由 loader 按顺序执行。
    // 如果有需要一个接一个请求 loader 的情况，需要修改此处。
    // 同时，此处还可以手动加全局拦截中间件。
    // 如果 各个 route 的 loader 无关先后，则不需要修改此处。
    async unstable_dataStrategy(...args) {
      // const r: Array<any> = [];
      // for await (const match of matches) {
      //   console.log(`Processing route ${match.route.id}`);
      //   const result = await match.resolve();
      //   r.push(result);
      //   console.log(`Done processing route ${match.route.id}`);
      // }
      // return r;
      // TODO: 此处做全局 登录态中间件， redirect 到登录
      // console.log(args, redirect);
      const matches = args[0].matches;
      // 登录页，直接放行

      initBuffetConfig();
      return Promise.all(
        matches.map(async (match) => {
          console.log(`Processing route ${match.route.id}`, match.route);
          // Don't override anything - just resolve route.lazy + call loader
          const result = await match.resolve();
          console.log(`Done processing route ${match.route.id}`);
          return result;
        }),
      );
    },
  },
);
